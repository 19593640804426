<template>
    <el-drawer
        title="编辑"
        :visible.sync="drawer"
        :modal="true"
        :wrapperClosable="false"
        size="600px"
        @close="closeDrawer"
        :modal-append-to-body="false"
        :direction="direction">

        <!-- title -->
        <div class="drawer_form">
            
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="120px" class="demo-ruleForm">
                
                <div class="form-body">
                    <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                        <el-form-item label="姓名" prop="">
                            <el-input type="text" v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="用户名" prop="">
                            <el-input type="text" v-model="form.username" autocomplete="off" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="">
                            <el-input type="password" v-model="form.password" autocomplete="off" placeholder="可填，默认身份证后六位"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="">
                            <el-input type="text" v-model="form.phone" autocomplete="off" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="评课次数" prop="">
                            <el-input type="text" v-model="form.limitCount" autocomplete="off" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="学科" prop="">
                            <el-cascader
                            :show-all-levels="false"
                            style="width: 100%;"
                            ref="cascader"
                            v-model="form.subjects"
                            :options="subject_lists"
                            :props="{ multiple: true,label:'name',value:'id',checkStrictly: true,emitPath:false }"
                            collapse-tags
                            clearable></el-cascader>
                        </el-form-item>
                        <el-form-item label="职称">
                            <el-select v-model="form.titleId" placeholder="请选择" class="w-full">
                                <el-option
                                v-for="item in title_lists"
                                :key="item.dictCode"
                                :label="item.dictValue"
                                :value="item.dictCode">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="标签" prop="">
                            <el-tag
                            :key="tag"
                            v-for="tag in form.tags"
                            closable
                            :disable-transitions="false"
                            @close="handleClose(tag)">
                            {{tag}}
                            </el-tag>
                            <el-input
                                
                                class="input-new-tag"
                                v-if="tags_inputVisible"
                                v-model="tags_value"
                                ref="saveTagInput"
                                size="small"
                                @keyup.enter.native="handleInputConfirm"
                                @blur="handleInputConfirm"
                            >
                            </el-input>
                            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加标签</el-button>
                        </el-form-item>

                    </overlay-scrollbars>
                </div>

                <div class="form-foot d-flex a-center j-center">
                    <el-button @click="onSubmit" type="primary" class="" style="width:200px">提交</el-button>
                </div>
            </el-form>
        </div>

    </el-drawer>
    
</template>

<script>
import {teacher_dict} from '@/request/api/dict.js'
import {edit,update} from '@/request/api/teacher.js'
import {tree} from '@/request/api/subject.js'
export default {
    data(){
        return {
            uploadUrl:'',
            drawer: true, //表单
            direction: 'rtl',//抽屉方向
            loading:false,
            form:{
                name:'', //真实姓名
                username:'', //用户名
                password:'', //密码
                phone:'', //手机
                titleId:'',//职称
                limitCount:'', //评课次数
                tags:[],//标签
                subjects:[],//学科 //[366],[365,368],[365,369]
            },
            tags_inputVisible:false,
            tags_value:'',
            title_lists:[], //职称
            subject_lists:[],
            rules:{
                type:[
                    { required: true, message: '职称必填', trigger: 'blur' },
                ],
            }
        }
    },
    created(){
        teacher_dict().then(res=>{
            this.title_lists = res.data
        })
        this.form.id = this.$route.query.id || ''
        this.init()
    },
    methods:{
        init(){
            edit(this.form.id).then(res=>{
                Object.keys(this.form).forEach(item=>{
                    if(typeof this.form[item] != 'object') {
                        this.form[item] = res.data[item]
                    }
                    // console.log(res.data['tags'])
                    this.form.tags =res.data['tags'] ? res.data['tags'].split(','):[]
                    this.form.titleId = this.form.titleId+''
                })
                let subjects = [];
                res.data.subjectsList && res.data.subjectsList.forEach(itx=>{
                    if(!itx.pid) {
                        //一级
                        subjects.push([itx.id])
                    }else{
                        subjects.push([itx.pid,itx.id])
                    }
                })
                this.form.subjects = subjects
           
            })
            tree().then(res=>{
                this.subject_lists = res.data
            })
        },
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.req()
                } else {
                    return false;
                }
            });
        },
        
        //req
        req(){
            let form = JSON.parse(JSON.stringify(this.form))
            form.tags = form.tags.join(',')
            // let subjects = [];
            // form.subjects.forEach(item=>{
            //     item.forEach(itx=>{
            //         if(!subjects.includes(itx)) {
            //             subjects.push(itx)
            //         }
            //     })
            // })
            // form.subjects = subjects.join(',')
            form.subjects = form.subjects.join(',')
            update(form).then(res=>{
                if(res.code!=0) return this.$notify.error({title:'错误',message: res.msg});
                this.$notify({title: '成功',message: res.msg,type: 'success'});
                this.$router.go(-1)
            })
        },
        
        handleClose(tag) {
            this.form.tags.splice(this.form.tags.indexOf(tag), 1);
        },
        showInput() {
            this.tags_inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.tags_value;
            if (inputValue) {
                this.form.tags.push(inputValue);
            }
            this.tags_inputVisible = false;
            this.tags_value = '';
        },

        //重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        //关闭抽屉
        closeDrawer(){
            this.$router.go(-1)
        }
    }

}
</script>


<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>

<style lang="less" scoped>
.drawer_form {
    width: 96%;
    margin: auto;
    .drawer_form_title {
        color: #17233d;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        line-height: 48px;
        margin-bottom: 20px;
    }

    .drawer_line {
        margin-bottom: 30px;
        .drawer_line_text {
            width: 120px;
            margin-right: 20px;
            text-align: right;
            color: #515a6e;
        }    
    }

}
.form-items {
    background-color:#f5f6fb;padding:15px 10px;height:500px;
}

/deep/ .form-items .el-form-item ,/deep/ .form-items .el-form-item .el-form-item__content{
    width: 100%;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>

